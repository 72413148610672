module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MRYSJ68ELC"],"pluginConfig":{"origin":"devops.dyrector.io"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","display":"standalone","icon":"src/images/favicon.png","name":"dyrector.io","short_name":"dyrector.io","start_url":"/","background_color":"#302474","theme_color":"#00D0BF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c7a60887605e55b6afa118ed7c930515"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[],"formats":["auto","webp"],"placeholder":"none"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
